import './App.css';
import NavHeader from "./NavHeader";
import inventory from "./inv.json";
import lsvInventory from "./lsv/lsv.json";
import Scooter from "./Scooter"
import Lsv from "./lsv/lsv";
import About from "./about";
import Mopeds from "./mopeds";
import HomeWidget from "./HomeWidget";
import { Route, Routes } from "react-router-dom";
import React from 'react';
import { BUSINESS_HOURS } from './constants';
function App() {

    let cc50;
    let cc150;

    //define 50cc scooters by looking for engines with "49cc" value in the inventory JSON
    cc50 = inventory.filter(inv => inv.details.engine === "49cc");

    //define 150cc scooters by looking for engines with "150cc" value in the inventory JSON
    cc150 = inventory.filter(inv => inv.details.engine === "150cc");

    // console.log(lsvInventory);

    return (
    <div className="App">
      <NavHeader></NavHeader>

      <div className="mainContent">
          <Routes>
              {/* Homepage Route */}
              <Route path="/" element={
                  <div className="inventoryList">

                      <div className="mainMobileHeader">
                          <p>Call / Text: <span className="nowrap">402-208-5111</span></p>
                          <p>{BUSINESS_HOURS}</p>
                          <p>1210 Royal Dr. Papillion, NE 68046</p>
                      </div>

                      <div className="homePage">
                          <p>If you're looking for a fun, efficient and convenient way to get around, look no further
                              than our selection of scooters and mopeds! We have a variety of models from Wolf brand
                              Scooters, so you can find the perfect ride for your needs. Whether you're commuting to and
                              from work, running errands around town, or just having some fun, our scooters and mopeds
                              will get you there in style. Don't miss out on our unbeatable prices and exceptional
                              customer service - shop our selection today!</p>
                      </div>

                      <div className="row">
                          <h2>50cc</h2>
                      </div>

                      <div className="row">
                          {/* List out all the results of the cc50 variable */}
                          {cc50.map((inv) => (
                              <HomeWidget key={inv.name} name={inv.name} link={inv.link} color={inv.details.colors} productType="scooters"></HomeWidget>
                          ))}
                      </div>

                      <div className="row">
                          <h2>150cc</h2>
                      </div>

                      <div className="row">
                          {/* List out all the results of the cc150 variable */}
                          {cc150.map((inv) => (
                              <HomeWidget key={inv.name} name={inv.name} link={inv.link} color={inv.details.colors} productType="scooters"></HomeWidget>
                          ))}
                      </div>
                      <div className="row">
                          <h2>Street Legal Electric Low Speed Vehicles (LSV)</h2>
                      </div>
                      <div className="row">

                          {lsvInventory.map(inv => (
                              <HomeWidget key={inv.name} name={inv.name} link={inv.link} color={inv.colors} productType="lsv"></HomeWidget>
                          ))}

                      </div>
                  </div>
              }>
              </Route>
              {/* /scooter/ Scooter name page */}
              <Route path="/scooter/:scootername" element={<Scooter/>}></Route>
              {/* /scooter/ Scooter name page */}
              <Route path="/lsv/:lsvname" element={<Lsv/>}></Route>
              {/* Mopeds  Page */}
              <Route path="/mopeds" element={<Mopeds/>}></Route>
              {/* About  Page */}
              <Route path="/about" element={<About/>}></Route>
          </Routes>



      </div>
    </div>
  );
}

export default App;
