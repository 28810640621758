import React, {Component} from 'react';

class Contactphones extends Component {
    render() {
        return (
            <div>
                <h2>Call us at <a href="tel:4023318400">402-331-8400</a></h2>
                <h2>Text us at <a href="sms:4022085111">402-208-5111</a></h2>
            </div>
        );
    }
}

export default Contactphones;