import React, { useState, useEffect } from 'react';
import inventory from "./inv.json";
import Contactphones from "./contactphones";
import { useParams } from "react-router-dom";
import "./scooter.sass"

function Scooter() {
    let { scootername } = useParams();
    let scooterMatch = inventory.find((scooter) => scooter.routing === scootername);

    // Move randoColor to a state variable, this used to be scooterColor
    const [randoColor] = useState(() => {
        let randoLimit = scooterMatch.details.colors.length;
        return scooterMatch.details.colors[Math.floor(Math.random() * randoLimit)];
    });

    const [selectedColor, setSelectedColor] = useState(randoColor);

    const [loadedImages, setLoadedImages] = useState({ 1: null, 2: null, 3: null, 4: null, 5: null, 6: null, 7: null });

    useEffect(() => {
        // Reset the loadedImages
        setLoadedImages({});

        scooterMatch.details.colors.forEach(color => {
            for (let i = 1; i <= 7; i++) {
                const imgSrc = "/scooters/" + scootername + "/" + color + "/" + i + ".jpg";
                const newImage = new Image();
                newImage.src = imgSrc; // Preload image
                newImage.onerror = () => {
                    console.log("Failed to load image: " + imgSrc);
                };
                newImage.onload = () => {
                    setLoadedImages(prevImages => ({
                        ...prevImages,
                        [color]: {
                            ...(prevImages[color] || {}), // This line ensures you're not overwriting the images of this color that might have already loaded
                            [i]: imgSrc
                        }
                    }));
                };
            }
        });

    }, [scootername, scooterMatch.details.colors]);

    let scooterPics = (
        <div className="gallery">
            {[1, 2, 3, 4, 5, 6, 7].map(idx =>
                loadedImages[selectedColor] && loadedImages[selectedColor][idx] &&
                <img key={idx} src={loadedImages[selectedColor][idx]} alt=""/>
            )}
        </div>
    );

    let price = scooterMatch.details.price.toLocaleString('en-US', {
        value: 'USD',
        minimumFractionDigits: 2
    });

    return (
        <div className="scooterPage">

            <div className="mobileHeader">
                <div className="flex">
                    <div>
                        <h1>{scooterMatch.name}</h1>
                    </div>
                    <div className="rightTxt">
                        <h1>$ {price}</h1>
                        <h1>{scooterMatch.stock}</h1>
                    </div>
                </div>
                <div className="scooterColors">
                    {scooterMatch.details.colors.map(color => (
                        <p className={'scooter-color swatch ' + color}
                           onClick={() => {
                               setSelectedColor(color);
                           }}
                           key={color}></p>
                    ))}
                </div>
            </div>


            <div id="dynamicGallery" className="galleryWrap">

                {scooterPics}

            </div>

            <div className="details">

                <div className="detailHeader">
                    <div className="flex">
                        <div>
                            <h1>{scooterMatch.name}</h1>
                        </div>
                        <div className="rightTxt">
                            <h1>$ {price}</h1>
                            <h1>{scooterMatch.stock}</h1>
                        </div>
                    </div>
                    <div className="scooterColors">
                        {scooterMatch.details.colors.map(color => (
                            <p className={'scooter-color swatch ' + color}
                               onClick={() => {
                                   setSelectedColor(color);
                               }}
                               key={color}></p>
                        ))}
                    </div>
                </div>

                <div className="interest">
                    <h1><em>Interested in this Scooter?</em></h1>
                    <Contactphones></Contactphones>
                </div>

                <div className="description">
                    <p>{scooterMatch.description}</p>
                </div>


                <div className="productDetails">
                    <h2>Details</h2>
                    <p><span>Engine:</span> <span>{scooterMatch.details.engine}</span></p>
                    <p><span>MPG:</span>{scooterMatch.details.mpg}</p>
                    <p><span>Transmission:</span> <span>{scooterMatch.details.transmission}</span></p>
                    <p><span>Drive:</span> <span>{scooterMatch.details.drive}</span></p>
                    <p><span>Displacement (ml):</span> <span>{scooterMatch.details.displacement}</span></p>
                    <p><span>Top Speed:</span> <span>{scooterMatch.details.topSpeed}</span></p>
                    <p><span>Front Breaks:</span> <span>{scooterMatch.details.fBreaks}</span></p>
                    <p><span>Rear Breaks:</span> {scooterMatch.details.rbreaks}</p>
                    <p><span>Front Tires:</span>{scooterMatch.details.fTires}</p>
                    <p><span>Rear Tires:</span>{scooterMatch.details.rTires}</p>
                    <p><span>Weight Capacity:</span>{scooterMatch.details.weightCapacity} lbs</p>
                    <p><span>Net Weight:</span>{scooterMatch.details.netWeight} lbs</p>
                    <p><span>Ground Clearance:</span>{scooterMatch.details.groundc}</p>
                    <p><span>Length:</span>{scooterMatch.details.length}</p>
                    <p><span>Width:</span>{scooterMatch.details.width}</p>
                    <p><span>Height:</span>{scooterMatch.details.height}</p>
                    <p><span>Seat Height:</span>{scooterMatch.details.seatHeight}</p>
                </div>

            </div>


        </div>
    );

}

export default Scooter;

