import React, {Component} from 'react';

class Mopeds extends Component {
    render() {
        return (
            <div className="mopedPage">
                <div>
                    <h1> What's the difference between Scooters and Mopeds?</h1>
                    <div>
                        <p>Scooters and mopeds are two types of motorized vehicles, but they are quite different. The most obvious difference between a scooter and a moped is the engine size. Scooters have larger engines than mopeds and can usually reach higher speeds. Scooters usually have a step-through design, which means that it is easier to mount and dismount the vehicle. Mopeds, on the other hand, usually have a regular frame with a low-profile seat.</p>
                        <p>Another difference between scooters and mopeds is their fuel efficiency. Scooters typically have better gas mileage than mopeds, due to their smaller engines. Scooters are also more agile and able to maneuver in tight spaces, while mopeds are more suitable for long-distance travel.</p>
                        <p>Finally, scooters and mopeds differ in the type of license required to operate them. Depending on the size of the engine, a scooter may require a motorcycle license, while a moped may only require a regular driver's license. In addition, some states may require a special permit to operate a moped, while others may not. Ultimately, it is important to check with your local laws and regulations to determine what type of license is necessary to operate either</p>
                    </div>
                    <h1> What's the difference between Scooters and Motorcycles?</h1>
                    <div>
                        <p>Scooters and motorcycles are both two-wheeled vehicles that have similar appearances. However, they serve different purposes and have distinct differences. Motorcycles are generally larger and more powerful than scooters, with engine sizes ranging from 50cc to over 2000cc. They are designed for speed and performance, and are used primarily for highway and long-distance travel.</p>
                        <p>Scooters, on the other hand, are smaller and more lightweight than motorcycles. They have engine sizes ranging from 50cc to 300cc, and are designed for city commuting and errand-running. They are more maneuverable than motorcycles, and are typically less expensive. They are also easier to park and store due to their smaller size.</p>
                        <p>Both scooters and motorcycles have their own unique benefits, and which one is best for you depends on your needs and preferences. If you want a vehicle for long-distance travel, a motorcycle is likely the better choice. But if you need something for getting around town, a scooter might be the better option. Whatever your needs, there is a two-wheeled vehicle to fit them.</p>
                    </div>
            </div>
            </div>
        );
    }
}

export default Mopeds;
